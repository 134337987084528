import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['country', 'prefixPhone']

    connect() {
        document.getElementById('orderForm_prefixPhone').placeholder =
            document.getElementById('orderForm_country').options[0].dataset.phonePrefix;
    }

    handleChange() {
        this.prefixPhoneTarget.placeholder = this.countryTarget.selectedOptions[0].dataset.phonePrefix;
    }
}
