import { Controller } from '@hotwired/stimulus';

const RADIO_SELECTION_TOGGLE = 'radio-selection-toggle'
const UPDATE_SUMMARY_TOGGLE = 'update-summary-toggle'
const IMAGE_ASSETS_PATH = 'assets/images/products/'

export default class extends Controller {
    static targets = [
        'toggleImage',
        'imageElement',
        'togglePaymentMethod',
        'handleUpdateSummary',
        'newCouponElement',
        'newCouponBtn',
        'displayCoupons',
        'existingCode'
    ]

    connect() {
        let defaultElement = null;
        let hasAValue = false;
        for (const element of document.getElementsByClassName('offerChoice')){
            if (element.checked === true) {
                hasAValue = true;
                this.imageElementTarget.src = IMAGE_ASSETS_PATH + element.attributes['data-order-image'].value;
            }
            if (element.attributes['data-is-pre-checked']) {
                defaultElement = element;
            }
            document.addEventListener(RADIO_SELECTION_TOGGLE, this.handleSelection.bind(this));
            document.addEventListener(UPDATE_SUMMARY_TOGGLE, this.handleUpdateSummary.bind(this));
        }

        if (hasAValue === false) {
            defaultElement.checked = true;
            this.imageElementTarget.src = IMAGE_ASSETS_PATH + defaultElement.attributes['data-order-image'].value;

        }

        let subscriptionPaymentElements = document.getElementsByClassName('payment-subscription');
        let normalPaymentElements = document.getElementsByClassName('payment-normal');

        let paymentElements = document.getElementsByName('orderForm[paymentMethod]');
        for (let paymentElement in paymentElements) {
            if (paymentElement.checked) {

            }
        }

    }

    toggleImage(event) {
        const selectionEvent = new CustomEvent(RADIO_SELECTION_TOGGLE, {
            detail: {
                element: event.target,
                imageSource: IMAGE_ASSETS_PATH + event.target.attributes['data-order-image'].value,
                summaryRow: event.target.attributes['data-summary-html'].value
            }
        });
        document.dispatchEvent(selectionEvent);

        return false;
    }

    togglePaymentMethod(event) {
        console.log(event)
    }

    handleSelection(event) {
        console.log(event)
        if (event.detail.element) {
            this.imageElementTarget.src = event.detail.imageSource;
            let orderRowsContainer = document.getElementById('order_resume');
            orderRowsContainer.innerHTML = event.detail.summaryRow;
        }
    }

    uncheckNormal() {
        let standardOffer = document.getElementsByName('orderForm[offers]');
        for (let offer of standardOffer) {
            offer.checked = false;
        }
    }

    uncheckRecurrent() {
        let abonamentOffer = document.getElementsByName('orderForm[offersWithSubscription]');
        for (let offer of abonamentOffer) {
            offer.checked = false;
        }
    }

    handleUpdateSummary(event) {
        let totalContainer = document.getElementById('summaryTotalContainer');
        let totalOfferContainer = document.getElementById('summaryTotalOfferContainer');
        let paymentMethodElement = document.getElementById('selectedPaymentMethodPrice');
        let deliveryMethodElement = document.getElementById('selectedDeliveryMethodPrice');

        document.getElementById('selectedDeliveryMethodPrice').innerHTML = spinner
        for (const element of document.getElementsByClassName('pret_livrare')){
            if (event.target.attributes['data-free-delivery'] != undefined && event.target.attributes['data-free-delivery'].value === 'data-free-delivery') {
                element.innerHTML = '0';
            } else {
                element.innerHTML = element.attributes['data-price'].value;
            }
        }

        const spinner = `<div class="spinner-border spinner-grow-sm text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>`;

        if (this.newCouponBtnTarget) {
            this.newCouponBtnTarget.disabled = true;
        }

        totalContainer.innerHTML = spinner;
        totalOfferContainer.innerHTML = spinner;
        paymentMethodElement.innerHTML = spinner;
        deliveryMethodElement.innerHTML = spinner;

        const formElement = document.querySelector("form[name=orderForm]");
        fetch("/functions/partial-update", {
            'method': 'POST',
            body: new FormData(formElement)
        })
            .then((response) => response.json())
            .then((result) => {console.log(result);
                totalContainer.innerText = result.total;
                totalOfferContainer.innerText = result.total_offers;
                paymentMethodElement.innerText = result.total_payment_method;
                deliveryMethodElement.innerText = result.total_delivery_method;

                let invalidCouponElement = document.getElementById('orderForm_couponForm_invalid');
                if (invalidCouponElement) {
                    if (result.invalid_coupon) {
                        invalidCouponElement.innerText = result.invalid_coupon;
                    } else {
                        invalidCouponElement.innerText = '';
                    }
                }

                let couponSummaryContainerElement = document.getElementById('couponContainerSummary');
                let couponSummaryElement = document.getElementById('summaryTotalCouponContainer');

                if (this.newCouponElementTarget) {
                    this.newCouponElementTarget.value = '';
                }

                if (this.existingCodeTarget) {
                    if (result.coupons && result.coupons.length > 0) {
                        this.existingCodeTarget.value = result.coupons[0].code;
                        this.displayCouponsTarget.innerHTML = result.coupons[0].html;
                    } else {
                        this.displayCouponsTarget.innerHTML = '';
                    }
                }

                if (couponSummaryContainerElement) {
                    if (parseFloat(result.total_coupons) < 0) {
                        couponSummaryElement.innerText = result.total_coupons;
                        couponSummaryContainerElement.hidden = false;

                    } else {
                        couponSummaryContainerElement.hidden = true;
                    }
                }

                for (const element of document.getElementsByClassName('pret_livrare')){
                    element.innerHTML = result.total_delivery_method;
                }

                this.newCouponBtnTarget.disabled = false;
            })
            .catch((error) => {console.error('Error: ', error)})
        ;
    }

    hideSubscriptionPayments() {
        let subscriptionPaymentElements = document.getElementsByClassName('form-check payment-subscription');
        let normalPaymentElements = document.getElementsByClassName('form-check payment-normal');

        let subscriptionOffers = document.getElementsByName('orderForm[offersWithSubscription]');
        for (let element of subscriptionOffers) {
            element.required = false;
        }

        let normalOffers = document.getElementsByName('orderForm[offers]');
        for (let element of normalOffers) {
            element.required = true;
        }

        let selectedElement = null;
        for (let paymentElement of subscriptionPaymentElements) {
            paymentElement.hidden = true;
            let inputElement = paymentElement.querySelectorAll('input')[0];
            if (inputElement.checked) {
                selectedElement = inputElement.attributes['data-method-name'].value;
                inputElement.checked = false;
            }
        }

        for (let paymentElement of normalPaymentElements) {
            paymentElement.hidden = false;
            let inputElement = paymentElement.querySelectorAll('input')[0];
            if (selectedElement && inputElement.attributes['data-method-name'].value === selectedElement) {
                inputElement.checked = true;
            }
        }
    }

    hideNormalPayments() {
        let subscriptionPaymentElements = document.getElementsByClassName('form-check payment-subscription');
        let normalPaymentElements = document.getElementsByClassName('form-check payment-normal');

        let subscriptionOffers = document.getElementsByName('orderForm[offersWithSubscription]');
        for (let element of subscriptionOffers) {
            element.required = true;
        }

        let normalOffers = document.getElementsByName('orderForm[offers]');
        for (let element of normalOffers) {
            element.required = false;
        }

        let selectedElement = null;

        for (let element of normalPaymentElements) {
            element.hidden = true;
            let inputElement = element.querySelectorAll('input')[0];
            if (inputElement.checked) {
                selectedElement = inputElement.attributes['data-method-name'].value;
                inputElement.checked = false;
            }

        }
        for (let element of subscriptionPaymentElements) {
            element.hidden = false;
            let inputElement = element.querySelectorAll('input')[0];
            if (selectedElement && inputElement.attributes['data-method-name'].value === selectedElement) {
                inputElement.checked = true;
            }
        }
    }
}