import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['isDeliveryDifferent', 'wrapper']

    connect() {
        let defaultElement = null;

    }

    toggle(event) {
        event.stopImmediatePropagation()
        this.wrapperTarget.hidden = !this.isDeliveryDifferentTarget.checked;
        console.log('da');
    }
}