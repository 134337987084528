import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'formElement'
    ];
    connect() {
        console.log(this.formElementTarget)
    }

    onSubmit(event) {
        event.preventDefault();

        const spinner = `<div class="spinner-border spinner-grow-sm text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>`;

        let btns = document.querySelectorAll('button[type=submit]');
        if (btns.length) {
            btns[0].innerHTML = spinner;
            btns[0].disabled = true;
        }

        this.formElementTarget.submit();
    }
}